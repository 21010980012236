import { push } from "connected-react-router";
import { showErrorMessage, showSuccessMessage } from './notification';
import agent from "../../agent";

// initial values
const defaultState = {
    professionalGroup: []
};


// Action types
const LOAD_PROFESSIONAL_GROUP = 'KapsuulConnect/professionalGroup/LOAD_PROFESSIONAL_GROUP';



// Reducer
export default function reducer(state = defaultState, action) {
    switch (action.type) {

        case LOAD_PROFESSIONAL_GROUP:
            return {
                ...state,
                professionalGroup: action.payload
            };
        default: return state
    }
};

//Action Creators


export function LoadProfessionalGroup(data) {
    return {
        type: LOAD_PROFESSIONAL_GROUP,
        payload: data
    };
}


//Actions


export function LoadAllProfessionalGroup() {
    return dispatch => {
        return agent.ProfessionalGroup.loadAll().then(
            response => {
                dispatch(LoadProfessionalGroup(response));
            },
            error => {
                dispatch(showErrorMessage(error));
            }
        );
    }
}

