import { getSearch, push } from "connected-react-router";
import { showErrorMessage, showSuccessMessage } from "./notification";
import agent from "../../agent";
import { loadLga, loadStates } from "./location";



// initial values
const account = {
  profile: {
    accountPackage: "Free",
    phoneNumber: "",
    country: "",
    state: "",
    lga: "",
    city: "",
    address: "",
    pcn: "",
    longitude: 0,
    latitude: 0,
    profileImage: "",
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    isPracticing: true,
    gender: "",
    isRegComplete: true,
    organizationName: "",
    organizationCategory: "",
    organizationCategory: "",
    numberofEmployees: 0,
    premisesImage: "",
    companyRegistrationNumber: "",
    yearofEstablishment: 0,
    website: "",
    openingTime: "",
    closingTime: "",
    sendEmail:[]
  },
  verifiedStaff: [],
  unverifiedStaff: [],
  searching: false,

};

// Action types
const UPDATE_PROFILE = "KapsuulConnect/account/UPDATE_PROFILE ";
const LOAD_PROFILE = "KapsuulConnect/account/LOAD_PROFILE";
const LOAD_PROFILE_BY_USER = "KapsuulConnect/account/LOAD_PROFILE_BY_USER";
const LOAD_ALL_USERS = "KapsuulConnect/account/LOAD_ALL_USERS";
const LOAD_ORGANIZATIONS = "LOAD_ORGANIZATIONS";
const LOAD_UNVERIFIEDSTAFF = "LOAD_UNVERIFIEDSTAFF";
const LOAD_VERIFIEDSTAFF = "LOAD_VERIFIEDSTAFF";
const SEARCHING = "SEARCHING";
// const VERIFYSTAFF = "VERIFYSTAFF"

// Reducer
export default function reducer(state = account, action = {}) {
  switch (action.type) {
    case UPDATE_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    case LOAD_PROFILE:
      return {
        ...state,
        profile: action.payload,
      };
    case LOAD_PROFILE_BY_USER:
      return {
        ...state,
        profile: action.payload,
      };
    case LOAD_ALL_USERS:
      return {
        ...state,
        profile: action.payload,
      };
    case LOAD_ORGANIZATIONS:
      return {
        ...state,
        error: null,
        organizations: action.payload,
        fetching: false,
      };
    case LOAD_UNVERIFIEDSTAFF:
      return {
        ...state,
        error: null,
        unverifiedStaff: action.payload,
        fetching: false,
      };
    case LOAD_VERIFIEDSTAFF:
      return {
        ...state,
        error: null,
        verifiedStaff: action.payload,
        fetching: false,
      };
    case SEARCHING:
      return {
        ...state,
        searching: action.payload,
      };
    default:
      return state;
  }
}

//Action Creators
export function LoadProfileData(data) {
  return {
    type: LOAD_PROFILE,
    payload: data,
  };
}

export function LoadProfileDataByUser(data) {
  return {
    type: LOAD_PROFILE_BY_USER,
    payload: data,
  };
}

export function onLoadAllUsers(data) {
  return {
    type: LOAD_ALL_USERS,
    payload: data,
  };
}

export function setSearching(status) {
  return {
    type: SEARCHING,
    payload: status,
  };
}

export function onLoadOrganizations(organizations) {
  return { type: LOAD_ORGANIZATIONS, payload: organizations };
}
export function onLoadUnverifiedStaff(unverifiedStaff) {
  return { type: LOAD_UNVERIFIEDSTAFF, payload: unverifiedStaff };


} export function onLoadVerifiedStaff(verifiedStaff) {
  return { type: LOAD_VERIFIEDSTAFF, payload: verifiedStaff };
}


//Actions

export function loadProfileWithData(email) {
  return (dispatch) => {
    return agent.Account.load(email).then((response) => {
      dispatch(LoadProfileData(response));
      dispatch(loadStates(response.country));
      dispatch(loadLga(response.state));
    });
  };
}

export function updateProfileIndividual(email, individual) {
  return (dispatch) => {
    return agent.Account.updateIndividualProfile(email, individual).then(
      (response) => {
        //handle success
        updateProfileAsComplete(response);
        dispatch(showSuccessMessage("Profile successfully updated"));
        dispatch(push("/blogs"));
      },
      (error) => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  };
}

export function updateProfileCorporate(email, corporate) {
  // const auth = JSON.parse(localStorage.getItem('auth'));
  return (dispatch) => {
    return agent.Account.updateCorporateProfile(email, corporate).then(
      (response) => {
        //handle success
        updateProfileAsComplete(response);
        dispatch(showSuccessMessage("Profile successfully updated"));
        dispatch(push("/blogs"));
      },
      (error) => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  };
}

export function updateProfilePicture(image) {
  return (dispatch) => {
    return agent.Account.updateProfilePicture(image).then(
      (response) => {
        //handle success
        dispatch(showSuccessMessage("Profile image successfully updated"));
      },
      (error) => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  };
}

export function loadAccountByUser(id) {
  return (dispatch) => {
    return agent.Account.getByID(id).then(
      (response) => {
        //handle success
        dispatch(LoadProfileDataByUser(response));
      },
      (error) => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  };
}

export function verifyPcn(id) {
  return (dispatch) => {
    return agent.Account.verifyPcn(id).then(
      (response) => {
        //handle success
        dispatch(showSuccessMessage("PCN number verified successfully."))
        setTimeout(() => {
          dispatch(push('/admin/accounts'));
        }, 2000)
      },
      (error) => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  };
}

// this is used for the contacts in Group list 
export function loadAllUsers(search, page) {
  return (dispatch) => {
    return agent.Account.loadAll(search, page).then(
      (response) => {
        dispatch(onLoadAllUsers(response));
      },
      (error) => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  };
}

export function loadContactsForGroup(search, groupId, page, take) {
  return (dispatch) => {
    return agent.Account.loadContactsForGroup(search, groupId, page, take).then(
      (response) => {
        dispatch(onLoadAllUsers(response));
      },
      (error) => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  };
}

export function loadUser(id) {
  return (dispatch) => {
    return agent.Account.getByID(id).then(
      (response) => {
        dispatch(LoadProfileDataByUser(response));
      },
      (error) => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  };
}


function updateProfileAsComplete(response) {
  if (response.isRegComplete) {
    const user = agent.Auth.current();
    const updatedProfile = { ...user, isRegComplete: true };
    agent.Auth.saveAuthData(updatedProfile);
  }
}

export function loadOrganizations() {
  return (dispatch) => {
    return agent.Account.getOrganizations().then(
      (response) => {
        dispatch(onLoadOrganizations(response));
      },
      (error) => {
        // dispatch(showErrorMessage(error));
      }
    );
  };
}

export function loadCompleted(page, search) {
  return (dispatch) => {
    return agent.Account.getCompletedAccounts(page, search).then(
      (response) => {
        dispatch(onLoadAllUsers(response));
      },
      (error) => {
        // dispatch(showErrorMessage(error));
      }
    );
  };
}

export function loadVerifiedStaff(id, search) {
  if (!search) {
    search = "";
  }
  return (dispatch) => {
    return agent.Account.getVerifiedStaff(id, search).then(
      (response) => {
        dispatch(onLoadVerifiedStaff(response));
        dispatch(searching(false));
      },
      (error) => {
        // dispatch(showErrorMessage(error));
        dispatch(searching(false));
      }
    );
  };
}
export function loadUnverifiedStaff(id, search) {
  if (!search) {
    search = "";
  }
  return (dispatch) => {
    return agent.Account.getUnverifiedStaff(id, search).then(
      (response) => {
        dispatch(onLoadUnverifiedStaff(response));
        dispatch(searching(false));
      },
      (error) => {
        dispatch(searching(false));
        // dispatch(showErrorMessage(error));
      }
    );
  };
}

export function searching(status) {
  return (dispatch) => {
    dispatch(setSearching(status));
  };
}


export function approveStaff(id) {
  return (dispatch) => {
    return agent.Account.ApproveStaff(id).then(
      (response) => {
        dispatch(onLoadVerifiedStaff(response));
      },
      (error) => {
        // dispatch(showErrorMessage(error));
      }
    );
  };
}
export function rejectStaff(id) {
  return (dispatch) => {
    return agent.Account.RejectStaff(id).then(
      (response) => {
        dispatch(onLoadUnverifiedStaff(response));
      },
      (error) => {
        // dispatch(showErrorMessage(error));
      }
    );
  };
}

// export function searchStaff(page, search) {
//   return dispatch => {
//     return agent.Account.loadAll(search, page).then(
//       response => {
//         //handle success
//         dispatch(onLoadVerifiedStaff(response));

//       },
//       error => {
//         //handle error
//         dispatch(showErrorMessage(error));
//       }
//     );
//   }
// }
