import superagentPromise from "superagent-promise";
import _superagent, { unsubscribe } from "superagent";
import store from "./store/store";
import { appGoesOffline } from "./store/modules/error";
import { scrollToTop } from "./store/modules/notification";
import { OnLogout } from "./store/modules/auth";

const superagent = superagentPromise(_superagent, global.Promise);

//DEVELOPMENT
//export const API_ROOT = "https://3212-102-89-40-167.eu.ngrok.io";
//export const CLIENT_URL = "http://localhost:3000";

//export const API_ROOT = "http://192.168.0.134:4500";
//export const CLIENT_URL = "http://localhost:3000";

export const API_ROOT = process.env.REACT_APP_API_ROOT;
export const CLIENT_URL = process.env.REACT_APP_CLIENT_URL;

//TESTING
//export const API_ROOT = "https://pharmakonnect-api.herokuapp.com";
//export const CLIENT_URL = 'https://pharmakonnect-app.herokuapp.com';

//PRODUCTION
// export const API_ROOT = "https://kapsuul-api-cc8el.ondigitalocean.app";
// export const CLIENT_URL = 'https://www.kapsuulconnect.com';

export const IMAGE_URL = API_ROOT + "/account/uploads/";

let token = null;
const responseBody = (res) => res.body;

const getAuthToken = () => {
    const auth = JSON.parse(window.localStorage.getItem("auth"));
    const token = auth ? auth.token : null;
    return token;
};

export const tokenPlugin = (req) => {
    const { dispatch } = store();
    token = getAuthToken();

    req.set("Accept", "application/json");
    req.on("response", function (res) {
        if (res.status === 401) {
            localStorage.setItem("lastAccessedUrl", window.location.pathname);
            dispatch(OnLogout());
        }

        if (res.status === (201 || 200)) {
            scrollToTop();
        }
    });
    if (token) {
        req.set("Authorization", `Bearer ${token}`);
    }

    req.on("error", (error) => {
        if (error.status === undefined) {
            dispatch(appGoesOffline());
            console.log("application is offline");

            return;
        }
    });
};

const requests = {
    del: (url) =>
        superagent.del(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
    get: (url) =>
        superagent.get(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
    put: (url, body) =>
        superagent
            .put(`${API_ROOT}${url}`, body)
            .use(tokenPlugin)
            .then(responseBody),
    post: (url, body) =>
        superagent
            .post(`${API_ROOT}${url}`, body)
            .use(tokenPlugin)
            .then(responseBody),
};

const Auth = {
    logError: (data) => requests.post("/v1/log/error", { data }),
    isAuth: () => {
        const token = getAuthToken();
        return !!token;
    },
    setToken: () => {
        const auth = JSON.parse(window.localStorage.getItem("auth"));
        token = auth.token;
    },
    saveAuthData: (_user) => {
        window.localStorage.setItem("auth", JSON.stringify(_user));
        token = _user.token;
    },
    saveFirstTime: () => {
        window.localStorage.setItem("ftime", true);
    },
    loggedInOnce: () => {
        return !!window.localStorage.getItem("ftime");
    },
    logout: () => {
        window.localStorage.removeItem("auth");
        window.localStorage.removeItem("lastAccessedUrl");
        token = null;
    },
    current: () => JSON.parse(window.localStorage.getItem("auth")),
    isAdmin: () => {
        const user = Auth.current();
        return user?.accountType === ("Admin" || "Developer");
    },
    login: (email, password) =>
        requests.post("/account/login", { email, password }),
    register: (data) => requests.post("/account/register", data),
    checkValidEmail: (email) =>
        requests.post(`/account/email/is-valid`, { email }),
    forgotPassword: (email) => requests.get(`/account/forgot-password/${email}`),
    resendEmail: (email) => requests.get(`/account/resend-verification/${email}`),
    updatePassword: (data) => requests.put(`/account/updatepassword`, data),
    resetPassword: (email, password, token) =>
        requests.post(`/account/reset-password`, { email, password, token }),
    sendResetToken: (email) =>
        requests.post(`/account/password/email`, { email }),
    verifyResetToken: (email, token) =>
        requests.post(`/account/password/verify-token`, { email, token }),
    unsubscribe: (user) =>
        requests.post(`/account/subscribe`, user)
};

const User = {
    save: (user) => requests.post("/user", user),
    load: () => requests.get("/user"),
    edit: (user) => requests.put("/user", user),
    delete: (id) => requests.del(`/user/${id}`),
    view: (id) => requests.get(`/user/${id}`),
};

const Contact = {
    save: (data) => requests.post("/contact", data),
    load: (from, search) =>
        requests.get(`/contact?from=${from}&search=${search}`),
    edit: (data) => requests.put("/contact", data),
    delete: (id) => requests.del(`/contact/${id}`),
    view: (id) => requests.get(`/contact/${id}`),
    viewGroup: (id) => requests.get(`/contact/${id}`),
    viewByAccount: (id) => requests.get(`/contact/byaccount/${id}`),
    loadChatContact: (search) => requests.get(`/contact/all/search?searchParam=${search}`),
    addToContacts: (id) => requests.post(`/contact/addToContacts/${id}`),
    filterContact: (filter) => requests.get(`/contact/filter?${filter}`),
};

const Group = {
    saveGroup: (data) => requests.post("/group", data),
    loadGroup: (page, group) => requests.get("/group", page, group),
    editGroup: (id, group) => requests.put(`/group/${id}`, group),
    viewGroup: (id) => requests.get(`/group/${id}`),
    deleteGroup: (id) => requests.del(`/group/${id}`),
    deleteGroupMember: (contactId, groupId, from) =>
        requests.del(`/group/groupmember/${contactId}/${groupId}/${from}`),
    saveMembers: (data) => requests.post("/group/members", data),
    saveGroupWithMembers: (data) => requests.post("/group/new/members", data),
    loadMembers: (id) => requests.get(`/group/groupcontacts/${id}`),
    loadGroupWithMembers: () => requests.get("/group/owner/members"),
};

const Conversation = {
    save: (data) => requests.post("/chat/conversation", data),
    load: () => requests.get(`/chat`),
    findBySearch: (value) => requests.get(`/chat/search?searchParam=${value}`),
    delete: (id) => requests.del(`/chat/${id}`),
    gateway: (data) => requests.post(`/chat/gateway`, data),
    view: (id) => requests.get(`/chat/conversationparticipant/${id}`),
    viewById: (id) => requests.get(`/chat/conversation/${id}`),
    deleteMessageById: (id) => requests.del(`/chat/conversation/message/${id}`),
    clear: (id) => requests.del(`/chat/clear/${id}`)
};


  
const Sector = {
    save: (data) => requests.post("/sector", data),
    load: () => requests.get("/sector"),
    search: (param) => requests.get("/sector" + param),
    edit: (sector) => requests.put("/sector", sector),
    delete: (id) => requests.del(`/sector/${id}`),
    view: (id) => requests.get(`/sector/${id}`),
};

const Account = {
    updateIndividualProfile: (email, data) =>
        requests.put(`/account/individual/${email}`, data),
    updateProfilePicture: (image) => requests.put("/account/uploads", image),
    load: (email) => requests.get(`/account/getbyemail/${email}`),
    loadAll: (search, page) =>
        requests.get(`/account/?search=${search}&page=${page}`),
    loadAllAvailable: (search, page, take) =>
        requests.get(
            `/account/contacts?search=${search}&page=${page}&take=${take}`
        ),
    updateCorporateProfile: (email, data) =>
        requests.put(`/account/cooperate/${email}`, data),
    getOrganizations: () => requests.get("/account/corporate/organizations"),
    getCompletedAccounts: (page, search) => requests.get(`/account/all/complete?page=${page}&search=${search}`),
    getByID: (id) => requests.get(`/account/${id}`),
    getUnverifiedStaff: (id, search) =>
        requests.get(`/account/staff/unverified/${id}?search=${search}`),
    getVerifiedStaff: (id, search) =>
        requests.get(`/account/staff/verified/${id}?search=${search}`),
    verifyPcn: (id) => requests.put(`/account/verify/pcn/${id}`),
    ApproveStaff: (id) => requests.put(`/account/verify/${id}`),
    RejectStaff: (id) => requests.put(`/account/reject/${id}`),
    loadContactsForGroup: (search, groupId, page, take) =>
        requests.get(`/account/availableUsersByContact/?search=${search}&groupId=${groupId}&page=${page}&take=${take}`),
    verifyEmail: (token) =>
        requests.get(`/account/verify/${token}`),
    saveUserDeviceToken: (userId, token) => 
        requests.post(`/account/save-device-token`, { userId, token }),
};

const Country = {
    load: () => requests.get("/country"),
};
const State = {
    loadByCountry: (countryid) =>
        requests.get(`/state/getbycountry/${countryid}`),
    getStates: () => requests.get("/state"),
};
const Lga = {
    loadByState: (stateid) => requests.get(`/lga/getbystate/${stateid}`),
};

const JobVacancy = {
    save: (jobvacancy) => requests.post("/jobvacancy", jobvacancy),
    load: (pageNumber) =>
        requests.get("/jobvacancy/applications/active", pageNumber),
    // loadAll: (pageNumber, search) =>
    //     requests.get('/jobvacancy', pageNumber, search),
    loadAll: (pageNumber, search) =>
        requests.get(`/jobvacancy/publicjobs/?page=${pageNumber}&search=${search}`),
    loadAllJobs: (pageNumber) => requests.get("/jobvacancy/job", pageNumber),
    view: (id) => requests.get(`/jobvacancy/${id}`),
    edit: (id, jobvacancy) => requests.put(`/jobvacancy/${id}`, jobvacancy),
    delete: (id) => requests.del(`/jobvacancy/${id}`),
    approve: (id, approval) =>
        requests.put(`/jobvacancy/approve/${id}`, approval),
    reject: (id, rejection) =>
        requests.put(`/jobvacancy/reject/${id}`, rejection),
    applyWithCv: (id, data) =>
        requests.post(`/jobvacancy/cv/${id}`, data),
};

const Outlet = {
    save: (outlet) => requests.post("/outlet", outlet),
    load: (pageNumber, search) =>
        requests.get(`/outlet/apps/active?page=${pageNumber}&search=${search}`),
    view: (id) => requests.get(`/outlet/${id}`),
    edit: (id, outlet) => requests.put(`/outlet/${id}`, outlet),
    delete: (id) => requests.del(`/outlet/${id}`),
};

const Poll = {
    save: (poll) => requests.post("/poll", poll),
    load: (pageNumber) => requests.get("/poll", pageNumber),
    loadPublishedPoll: (pageNumber) =>
        requests.get("/poll/all/published", pageNumber),
    loadSetting: () => requests.get("/poll/setting"),
    view: (id) => requests.get(`/poll/${id}`),
    summary: (id) => requests.get(`/poll/summary/${id}`),
    edit: (id, poll) => requests.put(`/poll/${id}`, poll),
    delete: (id) => requests.del(`/poll/${id}`),
    vote: (poll) => requests.post("/poll/vote", poll),
    publish: (id) => requests.put(`/poll/${id}/publish`),
    reject: (id, message) => requests.put(`/poll/${id}/reject`, { message }),
    deactivate: (id) => requests.put(`/poll/${id}/deactivate`),
    loadByOwner: (pageNumber) => requests.get("/poll/owner", pageNumber),
};

const ScheduleMeeting = {
    save: (schedulemeeting) =>
        requests.post("/meeting/schedule-meeting", schedulemeeting),
    load: () => requests.get("/meeting"),
    view: (id) => requests.get(`/meeting/${id}`),
    edit: (id, schedulemeeting) =>
        requests.put(`/meeting/${id}`, schedulemeeting),
    startmeeting: (id, startmeeting) =>
        requests.put(`/meeting/start-meeting${id}`, startmeeting),
    endmeeting: (id, endmeeting) =>
        requests.put(`/meeting/end-meeting${id}`, endmeeting),
    delete: (id) => requests.del(`/meeting/${id}`),
};

const Blog = {
    save: (blog) => requests.post("/article", blog),
    load: (pageNumber) =>
        requests.get("/article/applications/active", pageNumber),
    loadAll: (pageNumber,type) => requests.get(`/article?page=${pageNumber}&type=${type}`),
    view: (articleId) => requests.get(`/article/${articleId}`),
    edit: (id, blog) => requests.put(`/article/${id}`, blog),
    delete: (id) => requests.del(`/article/${id}`),
    publish: (articleId) => requests.put(`/article/${articleId}/publish`),
    reject: (articleId, message) =>
        requests.put(`/article/${articleId}/reject`, { message }),
    loadAllPublished: (pageNumber,type) =>
        requests.get(`/article/published?page=${pageNumber}&type=${type}`),
    loadByUser: (userId, pageNumber,type) =>
        requests.get(`/article/user/${userId}?page=${pageNumber}&type=${type}`),
    search: (pageNumber, search, type) =>
        requests.get(`/article/search?page=${pageNumber}&search=${search}&type=${type}`),
    like: (articleId) => requests.put(`/article/like/${articleId}`),
    dislike: (articleId) => requests.put(`/article/dislike/${articleId}`),
    viewCount: (articleId, userId) => requests.put(`/article/view/${articleId}/${userId}`),
    loadPublishedByUser: (userId, blogid) =>
        requests.get(`/article/user/published/${userId}/${blogid}`),
};

const Comment = {
    save: (articleId, data) =>
        requests.post(`/article/${articleId}/comment/`, data),
    edit: (articleId, commentId, comment) =>
        requests.put(`/article/${articleId}/comment/${commentId}`, {
            message: comment,
        }),
    loadAll: (articleId, page, take) =>
        requests.get(`/article/${articleId}/comment`, page, take),
    view: (articleId, commentId) =>
        requests.get(`/article/${articleId}/comment/${commentId}`),
    delete: (articleId, commentId) =>
        requests.del(`/article/${articleId}/comment/${commentId}`),
    like: (articleId, commentId) =>
        requests.put(`/article/${articleId}/comment/like/${commentId}`),
    dislike: (articleId, commentId) =>
        requests.put(`/article/${articleId}/comment/dislike/${commentId}`),
};

const Category = {
    save: (category) => requests.post("/category", category),
    load: (pageNumber) =>
        requests.get("/category/applications/active", pageNumber),
    loadAll: (pageNumber) => requests.get("/category", pageNumber),
    view: (id) => requests.get(`/category/${id}`),
    edit: (id, category) => requests.put(`/category/${id}`, category),
    delete: (id) => requests.del(`/category/${id}`),
    publish: (id, category) => requests.put(`/category/approve/${id}`, category),
};

const EventType = {
    save: (eventtype) => requests.post("/eventtype", eventtype),
    load: (pageNumber) => requests.get("/eventtype/", pageNumber),
    loadAll: (pageNumber) => requests.get("/eventtype", pageNumber),
    view: (id) => requests.get(`/eventtype/${id}`),
    edit: (id, eventtype) => requests.put(`/eventtype/${id}`, eventtype),
    delete: (id) => requests.del(`/eventtype/${id}`),
    publish: (id, category) => requests.put(`/category/approve/${id}`, category),
};

const Event = {
    save: (event) => requests.post("/event", event),
    saveReg: (event) => requests.post("/event/register", event),
    loadAll: (search, pageNumber) =>
        requests.get(`/event/?search=${search}&page=${pageNumber}`),
    loadByUser: (search, pageNumber) =>
        requests.get(`/event/myevent/?search=${search}&page=${pageNumber}`),
    loadAllEvents: (search, pageNumber) =>
        requests.get(`/event/allevents/?search=${search}&page=${pageNumber}`),
    view: (id) => requests.get(`/event/${id}`),
    edit: (id, event) => requests.put(`/event/${id}`, event),
    delete: (id) => requests.del(`/event/${id}`),
    cancel: (id, reason) => requests.put(`/event/cancel/${id}`, { reason }),
    publish: (id) => requests.put(`/event/publish/${id}`),
    extendPublishedEvent: (id, data) => requests.put(`/event/extend_published_event/${id}`, data),
    reject: (id, reason) => requests.put(`/event/reject/${id}`, { rejectionMessage: reason }),
    registration: (data) => requests.post("/event/register", data),
};

const UserRegister = {
    load: (pageNumber) => requests.get("/eventusers", pageNumber),
};

const AdvertCategory = {
    save: (data) => requests.post("/advertcategory", data),
    load: (pageNumber) => requests.get("/advertcategory", pageNumber),
    edit: (id, data) => requests.put("/advertcategory/" + id, data),
    delete: (id) => requests.del(`/advertcategory/${id}`),
    view: (id) => requests.get(`/advertcategory/${id}`),
};

const Advert = {
    save: (Advert) => requests.post("/advert", Advert),
    load: (pageNumber) => requests.get("/advert", pageNumber),
    loadAll: (pageNumber) => requests.get("/advert", pageNumber),
    loadByUser: (pageNumber) => requests.get("/advert/apps/active", pageNumber),
    loadApproved: (pageNumber, zone) =>
        requests.get(`/advert/applications/active?page=${pageNumber}&zone=${zone}`),
    view: (id) => requests.get(`/advert/${id}`),
    edit: (id, Advert) => requests.put(`/advert/${id}`, Advert),
    delete: (id) => requests.del(`/advert/${id}`),
    approve: (id, approval) => requests.put(`/advert/approve/${id}`, approval),
    reject: (id, rejection) => requests.put(`/advert/reject/${id}`, rejection),
};

const Notification = {
    loadAll: () => requests.get("/notification"),
    loadById: (id) => requests.get(`/notification/${id}`),
    loadByAccount: (id) => requests.get(`/notification/byaccount/${id}`),
    loadAllByAccount: (id, search, page) =>
        requests.get(
            `/notification/by_account/all?page=${page}&search=${search}&accountId=${id}`
        ),
    updateNot: (id) => requests.put(`/notification/${id}`),

    delete: (id) => requests.del(`/notification/${id}`),
    clearAll: () => requests.del(`/notification/me/clearall`)

};

const Dashboard = {
    adminAdverts: () => requests.get(`/dashboard/admin/adverts`),
    adminBlogs: () => requests.get(`/dashboard/admin/blogs`),
    adminEvents: () => requests.get(`/dashboard/admin/events`),
    adminJobs: () => requests.get(`/dashboard/admin/jobs`),
    adminStat: () => requests.get(`/dashboard/admin/stat`),
    corporateStat: () => requests.get(`/dashboard/corp/stat`),
    individualStat: () => requests.get(`/dashboard/ind/stat`),
    blogsLastest: () => requests.get(`/dashboard/blogs/latest`),
    jobLastest: () => requests.get(`/dashboard/jobs/latest`),
    staffUnverified: () => requests.get(`/dashboard/staff/unverified`),
    userEvents: () => requests.get(`/dashboard/user/events`),
};

const TypeOfPractice = {
    save: () =>
        requests.post(`/type-of-practice`),
    loadAll: () =>
        requests.get(`/type-of-practice`),
    load: (id) =>
        requests.get(`/type-of-practice/${id}`),
    update: (id) =>
        requests.put(`/type-of-practice/${id}`),
    delete: (id) =>
        requests.del(`/type-of-practice/${id}`),
}

const OrganizationCategory = {
    save: () =>
        requests.post(`/organization-category`),
    loadAll: () =>
        requests.get(`/organization-category`),
    load: (id) =>
        requests.get(`/organization-category/${id}`),
    update: (id) =>
        requests.put(`/organization-category/${id}`),
    delete: (id) =>
        requests.del(`/organization-category/${id}`),
}


const Profession = {
    save: () =>
        requests.post(`/profession`),
    loadAll: () =>
        requests.get(`/profession`),
    load: (id) =>
        requests.get(`/profession/${id}`),
    update: (id) =>
        requests.put(`/profession/${id}`),
    delete: (id) =>
        requests.del(`/profession/${id}`),
}

const ProfessionalGroup = {
    save: () =>
        requests.post(`/professional-group`),
    loadAll: () =>
        requests.get(`/professional-group`),
    load: (id) =>
        requests.get(`/professional-group/${id}`),
    update: (id) =>
        requests.put(`/professional-group/${id}`),
    delete: (id) =>
        requests.del(`/professional-group/${id}`),
}

const School = {
    save: () =>
        requests.post(`/school`),
    loadAll: () =>
        requests.get(`/school`),
    load: (id) =>
        requests.get(`/school/${id}`),
    update: (id) =>
        requests.put(`/school/${id}`),
    delete: (id) =>
        requests.del(`/school/${id}`),
}

const MembershipInterestGroup = {
    save: () =>
        requests.post(`/membership-interest-group`),
    loadAll: () =>
        requests.get(`/membership-interest-group`),
    load: (id) =>
        requests.get(`/membership-interest-group/${id}`),
    update: (id) =>
        requests.put(`/membership-interest-group/${id}`),
    delete: (id) =>
        requests.del(`/membership-interest-group/${id}`),
}

export default {
    Auth,
    User,
    Outlet,
    Sector,
    Account,
    Contact,
    Conversation,
    Country,
    State,
    Lga,
    JobVacancy,
    AdvertCategory,
    Advert,
    ScheduleMeeting,
    Poll,
    Blog,
    Category,
    Comment,
    EventType,
    Event,
    UserRegister,
    Notification,
    Group,
    Dashboard,
    TypeOfPractice,
    OrganizationCategory,
    Profession,
    ProfessionalGroup,
    School,
    MembershipInterestGroup,
    setToken: (_token) => {
        token = _token;
    },
};
