import { push } from "connected-react-router";
import { showErrorMessage, showSuccessMessage } from './notification';
import agent from "../../agent";
import { emptyContact, loadContactByAccountId, loadContactById } from "./contact";

// initial values
const defaultState = {
    conversations: [],
    conversation: {
        title: "",
        creatorId: "",
        channelId: "",
        isGroupChat: "",
        updatedOn: "",
        createdBy: "",
        updatedBy: ""
    },
    conversationById: {
        title: "",
        creatorId: "",
        channelId: "",
        isGroupChat: "",
        updatedOn: "",
        createdBy: "",
        updatedBy: ""
    }
};


// Action types
const CONVERSATION_LOADED = 'KapsuulConnect/contact/CONVERSATION_LOADED';
const CONVERSATIONS_LOADED = 'KapsuulConnect/contact/CONVERSATIONS_LOADED';
const CONVERSATIONBYID_LOADED = 'KapsuulConnect/contact/CONVERSATIONBYID_LOADED';
const CONV_EMPTIED = 'KapsuulConnect/contact/CONV_EMPTIED'


// Reducer
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case CONVERSATION_LOADED:
            return {
                ...state,
                conversation: action.payload
            }
        case CONVERSATIONBYID_LOADED:
            if(action.payload.fromSocket) {
                if(action.payload.isGroupChat) {
                    if(state.conversationById.isGroupChat && (state.conversationById.groupChatId === action.payload.groupChatId)) {
                        return {
                            ...state,
                            conversationById: action.payload
                        }

                    } else {
                        return {
                            ...state,
                            conversationByIdLoaded: {}
                        }
                    }


                } else {
                    if(state.conversationById.initiatorId === action.payload.initiatorId || state.conversationById.counterPartyId === action.payload.counterPartyId) {
                        return {
                            ...state,
                            conversationById: action.payload
                        }
                    } else {
                        return {
                            ...state,
                            conversationByIdLoaded: {}
                        }
                    }
                }
            } 
            return {
                    ...state,
                    conversationById: action.payload
                }
    
        case CONVERSATIONS_LOADED:
            return {
                ...state,
                conversations: action.payload
            }
        case CONV_EMPTIED:
            return {
                ...state,
                conversationById: {}
            }
        default: return state;
    }
}


// Action Creators
export function conversationLoaded(data) {
    return { type: CONVERSATION_LOADED, payload: data };
}

export function conversationByIdLoaded(data, fromSocket) {
    data.fromSocket = fromSocket;
    return { type: CONVERSATIONBYID_LOADED, payload: data };
}

export function conversationsLoaded(data) {
    return { type: CONVERSATIONS_LOADED, payload: data };
}


//Actions
export function createConvasation(convasation, contactId) {
    return dispatch => {
        return agent.Conversation.save(convasation).then(
            response => {
                //handle success
                //dispatch(showSuccessMessage(("Sent successfully")));
                //dispatch(push("/chat/conversation"));
                dispatch(loadConversation());
                dispatch(loadConversationById(response.id));
            },
            error => {
                //handle error
                dispatch(showErrorMessage(error));
            }
        );
    }
}


export function createConvasationGateway(convasation) {
    return dispatch => {
        return agent.Conversation.gateway(convasation).then(
            response => {
                //handle success
                dispatch(showSuccessMessage(("Sent successfully")));
                dispatch(push("/chat/gateway"));
            },
            error => {
                //handle error
                dispatch(showErrorMessage(error));
            }
        );
    }
}



export function deleteConversation(id) {
    return dispatch => {
        return agent.Conversation.delete(id).then(
            response => {
                //handle success
                dispatch(showSuccessMessage(("Conversation deleted successfully")));
                dispatch(push("/chat"));
            },
            error => {
                //handle error
                dispatch(showErrorMessage(error));
            }
        );
    }
}


export function Clearconversation(id) {
  return (dispatch) => {
    return agent.Conversation.clear(id).then(
      (response) => {
        //handle success
        dispatch(showSuccessMessage("Conversation deleted successfully"));
        dispatch(push("/chat"));
      },
      (error) => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  };
}

export function loadConversation() {
    return dispatch => {
        return agent.Conversation.load().then(
            response => {
                dispatch(conversationLoaded(response));
            },
            error => {
                //handle error
                dispatch(showErrorMessage(error));
            }
        );
    }
}

export function loadConversationWithSearch(val) {
    return dispatch => {
        return agent.Conversation.findBySearch(val).then(
            response => {
                dispatch(conversationLoaded(response));
            },
            error => {
                //handle error
                dispatch(showErrorMessage(error));
            }
        );
    }
}

export function emptyConversation() {
    return dispatch => {
        dispatch(convEmptied());
    }
}

export function convEmptied() {
    return { type: CONV_EMPTIED, payload: "" };
}

export function loadConversationById(id, ctcId, fromSocket = false) {
    const user = agent.Auth.current();
    return dispatch => {
        return agent.Conversation.viewById(id || ctcId).then(
            response => {
                if (response && response.isGroupChat) {
                    if (response && response.messages.length > 0) {
                        dispatch(conversationByIdLoaded(response, fromSocket));
                        dispatch(emptyContact())
                    } else {
                        dispatch(loadContactByAccountId(ctcId));
                        dispatch(emptyConversation())
                    }
                } else {
                    if ((response && response.messages.length > 0) && (response.initiatorId === user.accountId || response.counterPartyId === user.accountId)) {
                        dispatch(conversationByIdLoaded(response, fromSocket));
                        dispatch(emptyContact())
                    } else {
                        dispatch(loadContactByAccountId(ctcId));
                        dispatch(emptyConversation())
                    }
                }
            },
            error => {
                dispatch(showErrorMessage(error));
            });
    }
}

// export function deleteMessage(id) {
//     return dispatch => {
//         return agent.Message.delete(id).then(
//             response => {
//                 //handle success
//                 dispatch(showSuccessMessage(("Message deleted successfully")));
//                 // dispatch(push("/chat"));
//             },
//         );
//     }
// }

