import { push } from "connected-react-router";
import agent from "../../agent";

//intial types
const dashboard = {
    stat: {
        contact: 0,
        group: 0,
        blog: 0,
        advert: 0,
        event: 0,
        staff: 0,
        poll: 0,
        outlet: 0,
        account: {
            indivdual: 0,
            corporate: 0
        }
    },
    adminAdverts: [],
    adminEvents: [],
    userEvent: [],
    currentBlogs: [],
    unverifiedStaff: [],
    currentJobs: [],
    adminJobs: [],
    adminBlogs: [],


};
//Action types
const LOAD_ADMIN_STATISTICS = 'KapsuulConnect/dashboard/LOAD_ADMIN_STATISTICS';
const LOAD_ADMIN_ADVERT = "LOAD_ADMIN_ADVERT";
const LOAD_ADMIN_EVENT = "LOAD_ADMIN_EVENT";
const LOAD_ADMIN_JOB = "LOAD_ADMIN_JOB";
const LOAD_ADMIN_BLOG = "LOAD_ADMIN_BLOG";

const LOAD_CORPORATE_STATISTICS = 'KapsuulConnect/dashboard/LOAD_CORPORATE_STATISTICS';
const LOAD_CURRENT_JOB = "LOAD_CURRENT_JOB";
const LOAD_UNVERIFIED_STAFF = "LOAD_UNVERIFIED_STAFF"

const LOAD_INDIVIDUAL_STATISTICS = 'KapsuulConnect/dashboard/LOAD_INDIVIDUAL_STATISTICS';
const LOAD_USER_EVENTS = "LOAD_USER_EVENTS"
const LOAD_CURRENT_BLOGS = "LOAD_CURRENT_BLOGS"

//Reducers
export default function reducer(state = dashboard, action = {}) {
    switch (action.type) {
        case LOAD_ADMIN_STATISTICS:
            return {
                ...state,
                stat: action.payload,
            };
        case LOAD_ADMIN_ADVERT:
            return {
                ...state,
                error: null,
                adminAdverts: action.payload,
                fetching: false,
            };
        case LOAD_ADMIN_EVENT:
            return {
                ...state,
                error: null,
                adminEvents: action.payload,
                fetching: false,
            };
        case LOAD_ADMIN_JOB:
            return {
                ...state,
                error: null,
                adminJobs: action.payload,
                fetching: false,
            };
        case LOAD_ADMIN_BLOG:
            return {
                ...state,
                error: null,
                adminBlogs: action.payload,
                fetching: false,
            };
        case LOAD_CORPORATE_STATISTICS:
            return {
                ...state,
                stat: action.payload,
            };
        case LOAD_CURRENT_JOB:
            return {
                ...state,
                currentJobs: action.payload,
            };
        case LOAD_UNVERIFIED_STAFF:
            return {
                ...state,
                unverifiedStaff: action.payload,
            };
        case LOAD_INDIVIDUAL_STATISTICS:
            return {
                ...state,
                stat: action.payload,
            };
        case LOAD_USER_EVENTS:
            return {
                ...state,
                userEvent: action.payload,
            };
        case LOAD_CURRENT_BLOGS:
            return {
                ...state,
                currentBlogs: action.payload,
            };
        default:
            return state;
    }
}

//Action creator
export function onLoadAdminStatistics(data) {
    return {
        type: LOAD_ADMIN_STATISTICS,
        payload: data,
    };
}
export function onLoadAdminAdvert(adminAdverts) {
    return { type: LOAD_ADMIN_ADVERT, payload: adminAdverts };
}
export function onLoadAdminEvent(adminEvents) {
    return { type: LOAD_ADMIN_EVENT, payload: adminEvents };
}
export function onLoadAdminJob(adminJobs) {
    return { type: LOAD_ADMIN_JOB, payload: adminJobs };
}
export function onLoadAdminBlog(adminBlogs) {
    return { type: LOAD_ADMIN_BLOG, payload: adminBlogs };
}
export function onLoadCorporateStatistics(data) {
    return {
        type: LOAD_CORPORATE_STATISTICS,
        payload: data,
    };
}
export function onLoadCurrentjob(currentJobs) {
    return { type: LOAD_CURRENT_JOB, payload: currentJobs };
}
export function onLoadUnverifiedStaff(unverifiedStaff) {
    return { type: LOAD_UNVERIFIED_STAFF, payload: unverifiedStaff };
}
export function onLoadIndividualStatistics(data) {
    return {
        type: LOAD_INDIVIDUAL_STATISTICS,
        payload: data,
    };
}
export function onLoadUserEvents(userEvent) {
    return { type: LOAD_USER_EVENTS, payload: userEvent };
}
export function onLoadCurrentBlogs(currentBlogs) {
    return { type: LOAD_CURRENT_BLOGS, payload: currentBlogs };
}

//Action
//Admin
export function LoadAdminStatistics() {
    return (dispatch) => {
        return agent.Dashboard.adminStat().then(
            (response) => {
                dispatch(onLoadAdminStatistics(response));
            },

        );
    };
}
export function loadAdminAdvert() {
    return (dispatch) => {
        return agent.Dashboard.adminAdverts().then(
            (response) => {
                dispatch(onLoadAdminAdvert(response));
            },
        );
    };
}
export function loadAdminEvent() {
    return (dispatch) => {
        return agent.Dashboard.adminEvents().then(
            (response) => {
                dispatch(onLoadAdminEvent(response));
            },
        );
    };
}
export function loadAdminJob() {
    return (dispatch) => {
        return agent.Dashboard.adminJobs().then(
            (response) => {
                dispatch(onLoadAdminJob(response));
            },
        );
    };
}
export function loadAdminBlog() {
    return (dispatch) => {
        return agent.Dashboard.adminBlogs().then(
            (response) => {
                dispatch(onLoadAdminBlog(response));
            },
        );
    };
}
//Corporate
export function LoadCorporateStatistics() {
    return (dispatch) => {
        return agent.Dashboard.corporateStat().then(
            (response) => {
                dispatch(onLoadCorporateStatistics(response));
            },

        );
    };
}
export function loadCurrentJob() {
    return (dispatch) => {
        return agent.Dashboard.jobLastest().then(
            (response) => {
                dispatch(onLoadCurrentjob(response));
            },
        );
    };
}
export function loadUnverifiedStaff() {
    return (dispatch) => {
        return agent.Dashboard.staffUnverified().then(
            (response) => {
                dispatch(onLoadUnverifiedStaff(response));
            },
        );
    };
}

//Individual
export function LoadIndividualStatistics() {
    return (dispatch) => {
        return agent.Dashboard.individualStat().then(
            (response) => {
                dispatch(onLoadIndividualStatistics(response));
            },

        );
    };
}
export function LoadUserEvents() {
    return (dispatch) => {
        return agent.Dashboard.userEvents().then(
            (response) => {
                dispatch(onLoadUserEvents(response));
            },

        );
    };
}
export function LoadCurrentBlogs() {
    return (dispatch) => {
        return agent.Dashboard.blogsLastest().then(
            (response) => {
                dispatch(onLoadCurrentBlogs(response));
            },

        );
    };
}