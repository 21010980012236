import { push } from "connected-react-router";
import { showErrorMessage, showSuccessMessage } from './notification';
import agent from "../../agent";

// initial values
const defaultState = {
    organizationCategory: []
};


// Action types
const CREATE_ORGANIZATION_CATEGORY = 'KapsuulConnect/organizationCategory/CREATE_ORGANIZATION_CATEGORY';
const LOAD_ORGANIZATION_CATEGORY = 'KapsuulConnect/organizationCategory/LOAD_ORGANIZATION_CATEGORY';



// Reducer
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case CREATE_ORGANIZATION_CATEGORY:
            return {
                ...state,
                organizationCategory: action.payload
            };
        case LOAD_ORGANIZATION_CATEGORY:
            return {
                ...state,
                organizationCategory: action.payload
            };
        default: return state
    }
};

//Action Creators
export function CreateorganizationCategory(data) {
    return {
        type: CREATE_ORGANIZATION_CATEGORY,
        payload: data
    };
}

export function LoadOrganizationCategory(data) {
    return {
        type: LOAD_ORGANIZATION_CATEGORY,
        payload: data
    };
}


//Actions
export function CreateOrganizationCategory(organizationCategory) {
    return dispatch => {
        return agent.OrganizationCategory.save(organizationCategory).then(
            response => {
                dispatch(showSuccessMessage("Organization category successfully created"));
                dispatch(push("/admin/organizationCategory"));
            },
            error => {
                dispatch(showErrorMessage(error));
            }
        );
    }
}

export function LoadAllOrganizationCategory() {
    return dispatch => {
        return agent.OrganizationCategory.loadAll().then(
            response => {
                dispatch(LoadOrganizationCategory(response));
            },
            error => {
                dispatch(showErrorMessage(error));
            }
        );
    }
}

export function updateOrganizationCategory(id) {
    return dispatch => {
        return agent.OrganizationCategory.update(id).then(
            response => {
                //handle success
                dispatch(showSuccessMessage(("Organization category successfully updated")));
                dispatch(push(`/admin/organizationCategory`));
            },
            error => {
                //handle error
                dispatch(showErrorMessage(error));
            }
        );
    }
}
export function loadOrganizationCategory(id) {
    return dispatch => {
        return agent.OrganizationCategory.load(id).then(
            response => {
                dispatch(LoadOrganizationCategory(response));

            },
            error => {

                dispatch(showErrorMessage(error));
            }
        );
    }
}
export function deleteOrganizationCategory(id) {
    return dispatch => {
        return agent.OrganizationCategory.delete(id).then(
            response => {
                dispatch(push('/admin/organizationCategory'));
                // dispatch(LoadOrganizationCategory());
                dispatch(showSuccessMessage(("Organization category successfully deleted")));
            },
            error => {
                //handle error
                dispatch(showErrorMessage(error));
            }
        );
    }
}
