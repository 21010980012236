import { push } from "connected-react-router";
import { processed, processing, showErrorMessage, showSuccessMessage } from './notification';
import agent from "../../agent";
import { LoadProfileData, onLoadAllUsers, searching } from "./account";

// initial values
const defaultState = {
  contacts: [],
  contact: {
    accountId: "",
  },
  loading: false,
};


// Action types
const CONTACT_LOADED = 'KapsuulConnect/contact/CONTACT_LOADED';
const CONTACTS_LOADED = 'KapsuulConnect/contact/CONTACTS_LOADED';
const CTC_EMPTIED = 'KapsuulConnect/group/CTC_EMPTIED'
const LOADING = 'KapsuulConnect/group/LOADING'

// Reducer
export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case CONTACT_LOADED:
      return {
        ...state,
        contact: action.payload
      }
    case CONTACTS_LOADED:
      return {
        ...state,
        contacts: action.payload
      }
    case CTC_EMPTIED:
      return {
        ...state,
        contact: {}
      }
    case LOADING:
      return {
        ...state,
        loading: action.payload
      }
    default: return state;
  }
}


// Action Creators
export function contactLoaded(data) {
  return { type: CONTACT_LOADED, payload: data };
}

export function contactsLoaded(data) {
  return { type: CONTACTS_LOADED, payload: data };
}

export function ctcEmptied() {
  return { type: CTC_EMPTIED, payload: "" };
}

export function isloading(data) {
  return { type: LOADING, payload: data };
}

//Actions
export function createContact(contact) {
  return dispatch => {
    dispatch(processing());
    dispatch(isloading(true));
    return agent.Contact.save(contact).then(
      response => {
        //handle success
        dispatch(isloading(false));
        dispatch(processed());
        dispatch(showSuccessMessage(("Contact created successfully")));
        dispatch(push('/contacts'));
      },
      error => {
        //handle error
        dispatch(isloading(false));
        dispatch(processed());
        dispatch(showErrorMessage(error));
      }
    );
  }
}


export function editContact(contact) {
  return dispatch => {
    return agent.Contact.edit(contact).then(
      response => {
        //handle success
        dispatch(showSuccessMessage(("Contact updated successfully")));
        dispatch(push("/contacts"));
      },
      error => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  }
}
export function deleteContact(id) {
  return dispatch => {
    return agent.Contact.delete(id).then(
      response => {
        //handle success
        dispatch(showSuccessMessage(("Contact deleted successfully")));
        dispatch(push("/contacts"));
        // window.load();
      },
      error => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  }
}

export function loadContacts(from, search) {
  return dispatch => {
    dispatch(processing());
    return agent.Contact.load(from, search).then(
      response => {
        dispatch(processed());
        dispatch(contactsLoaded(response));
      },
      error => {
        //handle error
        dispatch(processed());
        dispatch(showErrorMessage(error));
      }
    );
  }
}

export function loadChatContacts(val) {
  return dispatch => {
    dispatch(processing());
    return agent.Contact.loadChatContact(val).then(
      response => {
        dispatch(processed());
        dispatch(contactsLoaded(response));
      },
      error => {
        //handle error
        dispatch(processed());
        dispatch(showErrorMessage(error));
      }
    );
  }
}


export function loadAvailableContacts(search, page, take) {
  return dispatch => {
    dispatch(searching(true));
    return agent.Account.loadAllAvailable(search, page, take).then(
      response => {
        dispatch(searching(false));
        dispatch(onLoadAllUsers(response));
      },
      error => {
        //handle error
        dispatch(searching(false));
        dispatch(showErrorMessage(error));
      }
    );
  }
}

export function loadContactById(id) {
  return dispatch => {
    return agent.Contact.view(id).then(
      response => {
        dispatch(contactLoaded(response));
      },
      error => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  }
}

export function loadContactByAccountId(id) {
  return dispatch => {
    return agent.Contact.viewByAccount(id).then(
      response => {
        dispatch(contactLoaded(response));
      },
      error => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  }
}

export function filterContacts(filter) {
  return dispatch => {
    dispatch(isloading(true))
    return agent.Contact.filterContact(filter).then(
      response => {
        dispatch(isloading(false));
        dispatch(LoadProfileData(response));
      },
      error => {
        //handle error
        //dispatch(showErrorMessage(error));
      }
    );
  }
}

export function emptyContact() {
  return dispatch => {

    dispatch(ctcEmptied());

  }
}

export function addToContacts(id, from) {
  return dispatch => {
    dispatch(processing());
    return agent.Contact.addToContacts(id).then(
      response => {
        //handle success
        dispatch(processed());
        dispatch(showSuccessMessage(("Contact added successfully")));
        dispatch(loadContacts('chat'));
        if (!from || from !== "chat") {
          dispatch(push("/contacts"));
        }

      },
      error => {
        //handle error
        dispatch(processed());
        dispatch(showErrorMessage(error));
        dispatch(processing());
      }
    );
  }
}
