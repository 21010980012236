import { push } from "connected-react-router";
import { showErrorMessage, showSuccessMessage } from './notification';
import agent from "../../agent";

// initial values
const defaultState = {
    school: []
};


// Action types
const LOAD_SCHOOL = 'KapsuulConnect/school/LOAD_SCHOOL';



// Reducer
export default function reducer(state = defaultState, action) {
    switch (action.type) {

        case LOAD_SCHOOL:
            return {
                ...state,
                school: action.payload
            };
        default: return state
    }
};

//Action Creators


export function LoadSchool(data) {
    return {
        type: LOAD_SCHOOL,
        payload: data
    };
}


//Actions


export function LoadAllSchool() {
    return dispatch => {
        return agent.School.loadAll().then(
            response => {
                dispatch(LoadSchool(response));
            },
            error => {
                dispatch(showErrorMessage(error));
            }
        );
    }
}

