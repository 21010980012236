import { offlineErrorOccured } from "./error";

// initial values
const notification = {
  error: "",
  success: "",
  warning: "",
  info: "",
  offline: false,
  processing: false,
  processed: true,
};

// Action types
const SUCCESS_DISPLAYED = "KapsuulConnect/notification/SUCCESS_DISPLAYED";
const PROCESSING = "KapsuulConnect/notification/PROCESSING";
const PROCESSED = "KapsuulConnect/notification/PROCESSED";
const EMAIL_VERIFIED = "KapsuulConnect/notification/EMAIL_VERIFIED";
const WARNING_DISPLAYED = "KapsuulConnect/notification/WARNING_DISPLAYED";
const ERROR_DISPLAYED = "KapsuulConnect/notification/ERROR_DISPLAYED";
const INFO_DISPLAYED = "KapsuulConnect/notification/INFO_DISPLAYED";
const OFFLINE_ERROR_DISPLAYED =
  "KapsuulConnect/notification/OFFLINE_ERROR_DISPLAYED";
const MESSAGE_CLEARED = "KapsuulConnect/notification/MESSAGE_CLEARED ";
const TIME_OUT = 10000;

// Reducer
export default function reducer(state = notification, action = {}) {
  switch (action.type) {
    case SUCCESS_DISPLAYED:
    case WARNING_DISPLAYED:
    case ERROR_DISPLAYED:
    case INFO_DISPLAYED:
      return {
        ...state,
        [action.notificationType]: action.payload,
        fetching: false,
      };


    case MESSAGE_CLEARED:
      return {
        ...state,
        error: null,
        success: null,
        warning: null,
        info: null,
        fetching: false,
        // offline: false,
        // emailVerified: false,
      };

    case PROCESSING:
      return {
        ...state,
        processing: true,
      };

    case PROCESSED:
      return {
        ...state,
        processing: false,
      };

    case OFFLINE_ERROR_DISPLAYED:
      return {
        ...state,
        offline: true
      };

    case EMAIL_VERIFIED:
      return {
        ...state,
        emailVerified: action.payload,
      };
    default:
      return state;
  }
}

//action creators
export function errorMessageDisplayed(data) {
  return {
    type: ERROR_DISPLAYED,
    payload: data,
    notificationType: "error",
  };
}

export function warningMessageDisplayed(data) {
  return {
    type: WARNING_DISPLAYED,
    payload: data,
    notificationType: "warning",
  };
}

export function successMessageDisplayed(data) {
  return {
    type: SUCCESS_DISPLAYED,
    payload: data,
    notificationType: "success",
  };
}

export function infoMessageDisplayed(data) {
  return {
    type: INFO_DISPLAYED,
    payload: data,
    notificationType: "info",
  };
}

export function messageCleared() {
  return {
    type: MESSAGE_CLEARED,
  };
}

export function offlineMessageDisplayed() {
  return {
    type: OFFLINE_ERROR_DISPLAYED,
  };
}

export function emailVerifiedDispatched(status) {
  return {
    type: EMAIL_VERIFIED,
    payload: status
  };
}

export function processing() {
  return {
    type: PROCESSING,
  };
}

export function processed() {
  return {
    type: PROCESSED,
  };
}

//actions
export function showSuccessMessage(message) {
  return (dispatch) => {
    dispatch(successMessageDisplayed(message));
    setTimeout(() => {
      dispatch(messageCleared());
    }, TIME_OUT);
  };
}

export function showErrorMessage(message) {
  return (dispatch) => {
    dispatch(errorMessageDisplayed(serializeError(message)));
    setTimeout(() => {
      dispatch(messageCleared());
    }, TIME_OUT);
  };
}

export function showWarningMessage(message) {
  return (dispatch) => {
    dispatch(warningMessageDisplayed(message));
    setTimeout(() => {
      dispatch(messageCleared());
    }, TIME_OUT);
  };
}

export function showInfoMessage(message) {
  return (dispatch) => {
    dispatch(infoMessageDisplayed(message));
    setTimeout(() => {
      dispatch(messageCleared());
    }, TIME_OUT);
  };
}

export function setEmailVerified() {
  console.log('EMAIL_VERIFIED');
  return (dispatch) => {
    dispatch(emailVerifiedDispatched(true));
    setTimeout(() => {
      // dispatch(emailVerifiedDispatched(false));
      dispatch(messageCleared());
    }, 20000);
  };
}


export function showOfflineError() {
  return (dispatch) => {
    dispatch(offlineMessageDisplayed());
  };
}

export function clearMessage() {
  return (dispatch) => {
    dispatch(messageCleared());
  };
}

export function scrollToTop() {
  window.scroll({ top: 0, behavior: 'smooth' });
}
export function serializeError(error) {
  scrollToTop();

  if (typeof error === "string") {
    return error;
  }

  if (Array.isArray(error) && error.length > 0) {
    return error.join(", ");
  }

  if (
    error.response &&
    typeof error.response === "object" &&
    error.response !== null
  ) {
    const { body } = error.response;
    if (typeof body === "object" && body !== null) {
      const { message, error: bodyError } = body;
      if (typeof message === "string" && message !== null) {
        return message;
      }

      if (Array.isArray(message) && message.length > 0) {
        return message.join(", ");
      }

      if (typeof bodyError === "string" && bodyError !== null) {
        return bodyError;
      }

      if (Array.isArray(bodyError) && bodyError.length > 0) {
        return bodyError.join(", ");
      }
    }
  }

  if (error.message && typeof error.message === "string" && error.message !== null) {
    // if (error.message.includes('the network is offline')) {
    //   return "";
    // }
    return error.message;
  }

  return "An error occurred, please try again later";
}
