import { push } from "connected-react-router";
import { showErrorMessage, showSuccessMessage, processing, processed, serializeError, setEmailVerified } from './notification';
import agent from "../../agent";

// initial values
const authData = {
  currentUser: {
    email: "",
    password: "",
    token: "",
    accountType: "",
    accountPackage: "",
    isRegComplete: false
  }
};


// Action types
const REGISTERED = 'KapsuulConnect/auth/REGISTERED';
const LOGGED_IN = 'KapsuulConnect/auth/LOGGED_IN';
const LOGGED_OUT = 'KapsuulConnect/auth/LOGGED_OUT';

// Reducer
export default function reducer(state = authData, action = {}) {
  switch (action.type) {
    case REGISTERED:
      return {
        ...state,
        error: null,
        fetching: false,
      };
    case LOGGED_IN:
      return {
        ...state,
        error: null,
        fetching: false,
        currentUser: action.payload
      };
    case LOGGED_OUT:
      return {
        ...state,
        fetching: false,
        currentUser: null
      };
    default: return state;
  }
}

// Action Creators
export function userRegistered() {
  return { type: REGISTERED };
}

export function userLoggedIn(user) {
  return { type: LOGGED_IN, payload: user };
}


export function userLoggedOut() {
  return {
    type: LOGGED_OUT
  }
}


//Actions
export function registerUser(user) {
  return dispatch => {
    dispatch(processing());
    return agent.Auth.register(user).then(
      response => {
        //handle success
        dispatch(processed());
        dispatch(showSuccessMessage(("Registration successful. Verify your email to continue")));
        dispatch(push("/login"));
      },
      error => {
        //handle error
        dispatch(processed());
        dispatch(showErrorMessage(error));
      }
    );
  }
}

export function loginUser({ email, password }) {
  return dispatch => {
    dispatch(processing());
    return agent.Auth.login(email, password).then(
      response => {
        //handle success
        dispatch(processed());
        dispatch(showSuccessMessage("login successful"));
        onLogin(dispatch, response);
        const ongoingUnscription = localStorage.getItem("theUnsubscribe");
        if (ongoingUnscription) {
          if (response.accountType === "professional") {
            dispatch(push("/professional"));
            return;
          } else if (response.accountType === "corporate") {
            dispatch(push("/corporate"));
            return;
          } else {
            dispatch(push("/guest"));
            return;
          }
        }
        const lastAccessedUrl = localStorage.getItem("lastAccessedUrl");
        if (lastAccessedUrl !== null) {
          if (response.accountType === ("Admin" || "Developer")) {
            dispatch(push('/admin'));
            return;
          } else {
            dispatch(push(lastAccessedUrl));
            return;
          }
        } else {
          if (response.accountType === ("Admin" || "Developer")) {
            dispatch(push('/admin'));
            return;
          } else {
            dispatch(push('/blogs'));
            return;
          }
        }

      },
      error => {
        if (serializeError(error) === "emailNotVerified") {
          dispatch(setEmailVerified(true));
          dispatch(processed());
          return;
        }
        dispatch(showErrorMessage(error));
        dispatch(processed());
      }
    );
  }
}

export function forgotPasswordUser(email) {
  return dispatch => {
    dispatch(processing());
    return agent.Auth.forgotPassword(email).then(
      response => {
        //handle success
        dispatch(processed());
        dispatch(showSuccessMessage(("Request sent, kindly click on the link that is sent to your email to continue.")));
        dispatch(push("/login"));
      },
      error => {
        //handle error
        dispatch(processed());
        dispatch(showErrorMessage(error));
      }
    );
  }
}

export function resendEmail(email) {
  return dispatch => {
    dispatch(processing());
    return agent.Auth.resendEmail(email).then(
      response => {
        //handle success
        dispatch(processed());
        dispatch(showSuccessMessage(("Request sent, kindly click on the link that is sent to your email to continue.")));
        dispatch(setEmailVerified(false));
      },
      error => {
        //handle error
        dispatch(processed());
        dispatch(showErrorMessage(error));
      }
    );
  }
}


export function resetPassword(email, password, token) {
  return dispatch => {
    dispatch(processing());
    return agent.Auth.resetPassword(email, password, token).then(
      response => {
        //handle success
        dispatch(processed());
        dispatch(showSuccessMessage(("Password reset was successfull")));
        dispatch(push("/login"));
      },
      error => {
        //handle error
        dispatch(processed());
        dispatch(showErrorMessage(error));
      }
    );
  }
}


export function verifyEmail(token) {
  return (dispatch) => {
    return agent.Account.verifyEmail(token).then(
      (response) => {
        if (response) {
          onLogin(dispatch, response);
          if (response.accountType === ("Admin" || "Developer")) {
            dispatch(push('/admin'));
            return;
          } else {
            dispatch(push('/blogs'));
            return;
          }
        }
      },
      (error) => {
        dispatch(showErrorMessage(error));
      }
    );
  };
}


function onLogin(dispatch, user) {
  agent.Auth.saveAuthData(user)
  dispatch(userLoggedIn(user))
}

export function OnLogout() {
  return dispatch => {
    agent.Auth.logout()
    dispatch(userLoggedOut())
    dispatch(push('/login'))
  }
}