import { combineReducers } from 'redux';
import auth from './auth';
import jobVacancy from './jobVacancy';
import notification from "./notification";
import account from './account';
import contact from './contact';
import outlet from "./outlet";
import blog from './blog';
import blogCategory from './blogCategory';
import comment from './comment';
import location from './location';
import poll from './poll';
import common from './common';
import conversation from './chat';
import error from './error';
import scheduleMeeting from './scheduleMeeting';
import eventType from './eventType';
import event from './event';
import advertcategory from './advertcategory';
import advert from './advert';
import userRegister from './userRegister';
import appNotification from './appNotification';
import group from './group';
import dashboard from './dashboard';
import typeOfPractice from './typeOfPractice';
import organizationCategory from './organizationCategory';
import profession from './profession';
import professionalGroup from './professionalGroup';
import school from './school';
import membershipInterestGroup from './membershipInterestGroup';



import { connectRouter } from 'connected-react-router';

const appReducer = (history) => combineReducers({
  router: connectRouter(history),
  common,
  error,
  auth,
  account,
  contact,
  conversation,
  outlet,
  blog,
  blogCategory,
  comment,
  notification,
  jobVacancy,
  location,
  poll,
  scheduleMeeting,
  eventType,
  event,
  advertcategory,
  advert,
  userRegister,
  appNotification,
  group,
  dashboard,
  typeOfPractice,
  organizationCategory,
  profession,
  professionalGroup,
  school,
  membershipInterestGroup,
});

export default appReducer;