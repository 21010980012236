import { push } from "connected-react-router";
import { processed, processing, showErrorMessage, showSuccessMessage } from './notification';
import agent from "../../agent";
import { onLoadAllUsers } from "./account";

// initial values
const defaultState = {
  groups: [],
  group: {
    name: "",
    description: "",
    coverImage: "",
    contacts: [],
    members: [],
  },
};


// Action types
const GROUP_LOADED = 'KapsuulConnect/group/GROUP_LOADED';
const GROUPS_LOADED = 'KapsuulConnect/group/GROUPS_LOADED';
const MEMBERS_LOADED = 'KapsuulConnect/group/MEMBERS_LOADED';

// Reducer
export default function reducer(state = defaultState, action) {
  switch (action.type) {

    case GROUP_LOADED:
      return {
        ...state,
        group: action.payload
      }
    case GROUPS_LOADED:
      return {
        ...state,
        groups: action.payload
      }
    case MEMBERS_LOADED:
      return {
        ...state,
        members: action.payload
      }
    default: return state;
  }
}


// Action Creators

export function groupLoaded(data) {
  return { type: GROUP_LOADED, payload: data };
}

export function groupsLoaded(data) {
  return { type: GROUPS_LOADED, payload: data };
}

export function membersLoaded(data) {
  return { type: MEMBERS_LOADED, payload: data };
}


//Actions

export function createGroup(group) {
  return dispatch => {
    return agent.Group.saveGroup(group).then(
      response => {
        //handle success
        dispatch(showSuccessMessage(("Group created successfully")));
        dispatch(push("/groups"));
      },
      error => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  }
}

export function createGroupMembers(data) {
  return dispatch => {
    dispatch(processing());
    return agent.Group.saveMembers(data).then(
      response => {
        //handle success
        dispatch(processed());
        dispatch(showSuccessMessage(("Members successfully added to your group")));
        // dispatch(push("/groups"));
      },
      error => {
        //handle error
        dispatch(processed());
        dispatch(showErrorMessage(error));
      }
    );
  }
}

export function createGroupWithMembers(data) {
  return dispatch => {
    dispatch(processing());
    return agent.Group.saveGroupWithMembers(data).then(
      response => {
        //handle success
        dispatch(processed());
        dispatch(showSuccessMessage(("New Group and Members created successfully")));
        // dispatch(push("/groups"));
      },
      error => {
        //handle error
        dispatch(processed());
        dispatch(showErrorMessage(error));
      }
    );
  }
}

export function GroupById(id) {
  return dispatch => {
    return agent.Group.viewGroup(id).then(
      response => {
        dispatch(groupLoaded(response));
      },
      error => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  }
}
export function loadGroups() {
  return dispatch => {
    return agent.Group.loadGroup().then(
      response => {
        dispatch(groupsLoaded(response));
      },
      error => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  }
}

export function loadMembers(groupid) {
  return dispatch => {
    return agent.Group.loadMembers(groupid).then(
      response => {
        dispatch(membersLoaded(response));
      },
      error => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  }
}

export function editGroup(groupId, group) {
  return dispatch => {
    return agent.Group.editGroup(groupId, group).then(
      response => {
        //handle success
        dispatch(showSuccessMessage(("Group updated successfully")));
        dispatch(push("/groups"));
      },
      error => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  }
}

export function deleteGroup(id) {
  return dispatch => {
    return agent.Group.deleteGroup(id).then(
      response => {
        //handle success
        dispatch(showSuccessMessage(("Group deleted successfully")));
        dispatch(push("/groups"));
      },
      error => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  }
}


export function deleteGroupMember(contactId, groupId, from) {
  return dispatch => {
    return agent.Group.deleteGroupMember(contactId, groupId, from).then(
      response => {
        //handle success
        dispatch(showSuccessMessage(("member deleted successfully")));
        // dispatch(loadMembers(groupId));
        window.location.reload();
      },
      error => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  }
}
