import { push } from "connected-react-router";
import {
  scrollToTop,
  showErrorMessage,
  showSuccessMessage,
} from "./notification";
import agent from "../../agent";

// initial values
const defaultState = {
  title: "",
  body: "",
  coverImage: "",
  categoryIds: [""],
};

// Action types
const BLOG_LOADED = "pharmakonnect/BLOG/BLOG_LOADED";
const BLOGS_LOADED = "pharmakonnect/BLOG/BLOGS_LOADED";
const USER_PUB_BLOGS_LOADED = "pharmakonnect/BLOG/USER_PUB_BLOGS_LOADED";

// Reducer
export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case BLOGS_LOADED:
      return {
        ...state,
        blogs: action.payload,
      };
    case BLOG_LOADED:
      return {
        ...state,
        blog: action.payload,
      };

    case USER_PUB_BLOGS_LOADED:
      return {
        ...state,
        userPubblogs: action.payload,
      };
    default:
      return state;
  }
}

// Action Creators
export function blogLoaded(data) {
  return { type: BLOG_LOADED, payload: data };
}

export function blogsLoaded(data) {
  return { type: BLOGS_LOADED, payload: data };
}

export function userPubblogsLoaded(data) {
  return { type: USER_PUB_BLOGS_LOADED, payload: data };
}

//Actions
export function createBlog(blog) {
  return (dispatch) => {
    return agent.Blog.save(blog).then(
      (response) => {
        //handle success
        if (response.type === "blog") {
          dispatch(showSuccessMessage("Blog created successfully"));
          dispatch(push("/blogs"));
        } else {
          dispatch(showSuccessMessage("News created successfully"));
          dispatch(push("/news"));
 
        }
      },
      (error) => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  };
}

export function editBlog(id, blog) {
  return (dispatch) => {
    return agent.Blog.edit(id, blog).then(
      (response) => {
        //handle success
        dispatch(showSuccessMessage("Updated successfully"));
        dispatch(push(`/blog/view/${id}`));
      },
      (error) => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  };
}

export function publishBlog(id) {
  return (dispatch) => {
    return agent.Blog.publish(id).then(
      (response) => {
        dispatch(showSuccessMessage("Published successfully"));
        if(response.type === "blog"){
          dispatch(push("/admin/blogs"));
        }else{
          dispatch(push("/admin/news"));
        }
      },
      (error) => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  };
}

export function rejectBlog(id, reason) {
  return (dispatch) => {
    return agent.Blog.reject(id, reason).then(
      (response) => {
        //handle success
        dispatch(showSuccessMessage("Rejected successfully"));
        if(response.type === "blog"){
          dispatch(push("/admin/blogs"));
        }else{
          dispatch(push("/admin/news"));
        }      },
      (error) => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  };
}

export function deleteBlog(id) {
  return (dispatch) => {
    return agent.Blog.delete(id).then(
      (response) => {
        //handle success
        dispatch(showSuccessMessage("Deleted successfully"));
        if(response.type === "blog"){
          dispatch(push("/blogs"));
        }else{
          dispatch(push("/news"));
        }      },
      (error) => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  };
}

export function loadBlogs(pageNumber,type) {
  return (dispatch) => {
    return agent.Blog.loadAll(pageNumber,type).then(
      (response) => {
        //handle success
        dispatch(blogsLoaded(response));
      },
      (error) => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  };
}

export function loadPublishedBlogs(pageNumber, type) {
  return (dispatch) => {
    return agent.Blog.loadAllPublished(pageNumber,type).then(
      (response) => {
        //handle success
        dispatch(blogsLoaded(response));

        window.scroll({ top: 0, behavior: "smooth" });
      },
      (error) => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  };
}

export function loadBlog(id) {
  return (dispatch) => {
    return agent.Blog.view(id).then(
      (response) => {
        //handle success
        dispatch(viewBlogCount(id));
      },
      (error) => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  };
}

export function loadUserBlogs(id, pageNumber, type) {
  return (dispatch) => {
    return agent.Blog.loadByUser(id, pageNumber, type).then(
      (response) => {
        //handle success
        dispatch(blogsLoaded(response));
      },
      (error) => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  };
}

export function searchBlogs(pageNumber, search, type) {
  return (dispatch) => {
    return agent.Blog.search(pageNumber, search, type).then(
      (response) => {
        //handle success
        dispatch(blogsLoaded(response));
      },
      (error) => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  };
}

export function likeBlog(articleId) {
  return (dispatch) => {
    return agent.Blog.like(articleId).then(
      (response) => {
        //handle success
        dispatch(showSuccessMessage(""));
        // dispatch(commentCreated(response))
        dispatch(loadBlog(articleId));
      },
      (error) => {
        //handle error
        // dispatch(showErrorMessage(error));
      }
    );
  };
}

export function dislikeBlog(articleId) {
  return (dispatch) => {
    return agent.Blog.dislike(articleId).then(
      (response) => {
        //handle success
        dispatch(showSuccessMessage(""));
        // dispatch(commentCreated(response))
        dispatch(loadBlog(articleId));
      },
      (error) => {
        //handle error
        // dispatch(showErrorMessage(error));
      }
    );
  };
}

export function viewBlogCount(id) {
  return (dispatch) => {
    const user = agent.Auth.current();
    return agent.Blog.viewCount(id, user?.accountId || undefined).then(
      (response) => {
        //handle success
        dispatch(blogLoaded(response));
      },
      (error) => {
        //handle error
        // dispatch(showErrorMessage(error));
      }
    );
  };
}

export function userPublishedBlog(id, blogid) {
  return (dispatch) => {
    return agent.Blog.loadPublishedByUser(id, blogid).then(
      (response) => {
        //handle success
        dispatch(userPubblogsLoaded(response));
      },
      (error) => {
        //handle error
        // dispatch(showErrorMessage(error));
      }
    );
  };
}
