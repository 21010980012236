import { showErrorMessage, showSuccessMessage } from './notification';
import agent from "../../agent";
import { push } from 'connected-react-router';

// initial values
const defaultState = {
  advertcategories: [],
  advertcategory: {
    name: "",
    description: ""
  }
}


// Action types
const ADVERTCATEGORY_LOADED = 'KapsuulConnect/advertcategory/ADVERTCATEGORY_LOADED';
const ADVERTCATEGORIES_LOADED = 'KapsuulConnect/advertcategory/ADVERTCATEGORYS_LOADED';


// Reducer
export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case ADVERTCATEGORY_LOADED:
      return {
        ...state,
        advertcategory: action.payload
      }
    case ADVERTCATEGORIES_LOADED:
      return {
        ...state,
        advertcategories: action.payload
      }
    default: return state;
  }
}
// Action Creators
export function advertCategoryLoaded(data) {
  return { type: ADVERTCATEGORY_LOADED, payload: data };
}

export function advertCategoriesLoaded(data) {
  return { type: ADVERTCATEGORIES_LOADED, payload: data };
}

//Actions
export function createAdvertCategory(advertcategory) {
  return dispatch => {
    return agent.AdvertCategory.save(advertcategory).then(
      response => {
        //handle success
        dispatch(showSuccessMessage(("Advert Category created successfully")));
        dispatch(push("/admin/advertcategory"));
      },
      error => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  }
}


export function editAdvertCategory(id, advertcategory) {
  return dispatch => {
    return agent.AdvertCategory.edit(id, advertcategory).then(
      response => {
        //handle success
        dispatch(showSuccessMessage(("Advert catgory updated successfully")));
        dispatch(push("/admin/advertcategory"));
      },
      error => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  }
}

export function deleteAdvertCategory(id) {
  return dispatch => {
    return agent.AdvertCategory.delete(id).then(
      response => {
        //handle success
        dispatch(push("/admin/advertcategory"));
        dispatch(showSuccessMessage(("Advert Category deleted successfully")));
      },
      error => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  }
}

export function loadAdvertCategory() {
  return dispatch => {
    return agent.AdvertCategory.load().then(
      response => {
        dispatch(advertCategoriesLoaded(response));
      },
      error => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  }
}

export function loadOneAdvertCategory(id) {
  return dispatch => {
    return agent.AdvertCategory.view(id).then(
      response => {
        dispatch(advertCategoryLoaded(response));
      },
      error => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  }
}