import { push } from "connected-react-router";
import { showErrorMessage, showSuccessMessage } from './notification';
import agent from "../../agent";

// initial values
const defaultState = {
    membershipInterestGroup: []
};


// Action types
const LOAD_MEMBERSHIP_INTEREST_GROUP = 'KapsuulConnect/membershipInterestGroup/LOAD_MEMBERSHIP_INTEREST_GROUP';



// Reducer
export default function reducer(state = defaultState, action) {
    switch (action.type) {

        case LOAD_MEMBERSHIP_INTEREST_GROUP:
            return {
                ...state,
                membershipInterestGroup: action.payload
            };
        default: return state
    }
};

//Action Creators


export function LoadMembershipInterestGroup(data) {
    return {
        type: LOAD_MEMBERSHIP_INTEREST_GROUP,
        payload: data
    };
}


//Actions


export function LoadAllMembershipInterestGroup() {
    return dispatch => {
        return agent.MembershipInterestGroup.loadAll().then(
            response => {
                dispatch(LoadMembershipInterestGroup(response));
            },
            error => {
                dispatch(showErrorMessage(error));
            }
        );
    }
}

