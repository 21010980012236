import { push } from "connected-react-router";
import agent from "../../agent";
import { showErrorMessage, showSuccessMessage } from "./notification";

//Initial Values
const defaultState = {
  adverts: [],
  advert: {
    title: "",
    url: "",
    publishedAt: "",
    startDate: "",
    endDate: "",
    advertiserId: "",
    advertCategory: "",
    zone: "",
    companyName: "",
    website: "",
    contactPerson: "",
    contactPhoneNumber: "",
    description: "",
    minAdvertDuration: "",
  }
}

// Action types
const ADVERT_LOADED = 'KapsuulConnect/advertcategory/ADVERT_LOADED';
const ADVERTS_LOADED = 'KapsuulConnect/advertcategory/ADVERTS_LOADED';


// Reducer
export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case ADVERT_LOADED:
      return {
        ...state,
        advert: action.payload
      }
    case ADVERTS_LOADED:
      return {
        ...state,
        adverts: action.payload
      }
    default: return state;
  }
}

// Action Creators
export function advertLoaded(data) {
  return { type: ADVERT_LOADED, payload: data };
}

export function advertsLoaded(data) {
  return { type: ADVERTS_LOADED, payload: data };
}


//Actions
export function createAdvert(advert) {
  return dispatch => {
    return agent.Advert.save(advert).then(
      response => {
        //handle success
        if (agent.Auth.isAdmin()) {
          dispatch(push("/admin/advert"));
        }
        else {
          dispatch(push("/advert"));
        }
        dispatch(showSuccessMessage(("Advert created successfully")));

      },
      error => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  }
}

export function settingAdvert(advert) {
  return dispatch => {
    return agent.Advert.save(advert).then(
      response => {
        //handle success
        dispatch(showSuccessMessage(("Setting is successfully")));

      },
      error => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  }
}


export function editAdvert(id, advert) {
  return dispatch => {
    return agent.Advert.edit(id, advert).then(
      response => {

        if (agent.Auth.isAdmin()) {
          dispatch(push("/admin/advert"));
        }
        else {
          dispatch(push("/advert"));
        }
        //handle success
        dispatch(showSuccessMessage(("Advert updated successfully")));
      },
      error => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  }
}

export function loadOneAdvert(id) {
  return dispatch => {
    return agent.Advert.view(id).then(
      response => {
        dispatch(advertLoaded(response));

      },
      error => {
        //handle error
        // dispatch(showErrorMessage(error));
      }
    );
  }
}

export function deleteAdvert(id) {
  return dispatch => {
    return agent.Advert.delete(id).then(
      response => {
        //handle success
        if (agent.Auth.isAdmin()) {
          dispatch(push("/admin/advert"));
        }
        else {
          dispatch(push("/advert"));
        }
        dispatch(advertLoaded());
        dispatch(showSuccessMessage(("Advert deleted successfully")));


      },
      error => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  }
}

export function loadAdvert(pageNumber) {
  return dispatch => {
    return agent.Advert.load(pageNumber).then(
      response => {
        //dispatch(push("/admin/advert"));
        dispatch(advertsLoaded(response));
      },
      error => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  }
}


export function loadUserAdvert(pageNumber) {
  return dispatch => {
    return agent.Advert.loadByUser(pageNumber).then(
      response => {
        dispatch(advertsLoaded(response));
      },
      error => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  }
}

export function loadApprovedAdverts(page, zone) {
  return dispatch => {
    return agent.Advert.loadApproved(page, zone).then(
      response => {
        dispatch(advertsLoaded(response));
      },
      error => {
        //handle error
        // dispatch(showErrorMessage(error));
      }
    );
  }
}

export function loadAdvertImages() {
  return dispatch => {
    return agent.Advert.load().then(
      response => {
        dispatch(advertsLoaded(response));
      },
      error => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  }
}

export function approveAdvert(id, approval) {
  return dispatch => {
    return agent.Advert.approve(id, approval).then(
      response => {
        //handle success
        // dispatch(showSuccessMessage(("Job Vacancy has been approved")));
        dispatch(push("/admin/advert"));
        dispatch(advertsLoaded(response));

      },
      error => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  }
}

export function rejectAdvert(id, rejection) {
  return dispatch => {
    return agent.Advert.reject(id, rejection).then(
      response => {
        //handle success
        // dispatch(showSuccessMessage(("Job Vacancy has been rejected")));
      },
      error => {
        //handle error
        dispatch(showErrorMessage(error));
      }
    );
  }
}