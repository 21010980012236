import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
// import 'bootswatch/dist/pulse/bootstrap.min.css';
import io from "socket.io-client";
import Swal from "sweetalert2";

import agent, { API_ROOT } from '../agent';
import { UserNotifications } from '../store/modules/appNotification';
import { loadConversation, loadConversationById } from '../store/modules/chat';
import AppLoading from './UI/Spinner/AppLoading';
import AppUrlListener from './AppUrlListener';



const AppRouter = React.lazy(() => import('../components/Shared/AppRouter'));
toast.configure({
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
});

function App(props) {
  const dispatch = useDispatch();

  // const conversation = useSelector(state => state.conversation.conversationById);

  AppUrlListener();

  useEffect(() => {
    if (agent.Auth.isAuth()) {
      //dispatch(loadApp())
    }
  }, [dispatch])

  const user = agent.Auth.current();

  useEffect(() => {
    const socket = io(`${API_ROOT}/chatgateway`);

    socket.on('connect', () => {
      if (user && user.accountId) {
        socket.emit('msgToServer', {
          socketId: user.accountId,
        })
      }
      socket.on("msgToClient", data => {
        dispatch(loadConversation());
        if (data.messages.length <= 0) {
          return;
        } else {
          //if(conversation.initiatorId === data.initiatorId || conversation.counterPartyId === data.counterPartyId) {
            dispatch(loadConversationById(data.id, user.accountId === data.initiatorId ? data.counterPartyId : data.initiatorId, true));
          //}
        }
      });

    })



    return () => socket.disconnect();

  }, []);

  useEffect(() => {
    const notisocket = io(`${API_ROOT}/notigateway`);

    notisocket.on('connect', () => {
      if (user && user.accountId) {
        notisocket.emit('notimsgToServer', {
          socketId: user.accountId,
        })
      }

      notisocket.on("notimsgToClient", () => {
        //dispatch(loadUserNavbarNotifications(data));
        dispatch(UserNotifications(user.accountId));

      });
    })

    return () => notisocket.disconnect();
  }, []);

  useEffect(() => {
    const ua = window.navigator.userAgent;
    const isIPad = !!ua.match(/iPad/i);
    const isIPhone = !!ua.match(/iPhone/i)
    const isIOS = isIPad || isIPhone;


    // const today = moment().toDate();
    // const lastPrompt = moment(localStorage.getItem("installPrompt"));
    // const days = moment(today).diff(lastPrompt, "days");
    // const webkit = !!ua.match(/WebKit/i);

    // const isSafari = isIOS && webkit && !ua.match(/CriOS/i);

    // const prompt = (isNaN(days) || days > 30) && isIOS && isSafari;

    // if (prompt && "localStorage" in window) {
    //     localStorage.setItem("installPrompt", today);
    // }

    if (isIOS) {
      if (!window.navigator.standalone) {
        Swal.fire({
          title: 'Install Kapsuul',
          html:
            '<div> Install this application on your homescreen for a better experience <br></br> <strong> Tap <img src="assets/ios-menu-btn.png"> and then "Add to Home screen" </strong> </div>',
          showCloseButton: true,
          allowOutsideClick: () => false,
          showCancelButton: false,
          showConfirmButton: false,
          position: 'center'
        })
      }
    }
  }, [])
  

  return (
    <>
    <AppUrlListener></AppUrlListener>
    <React.Suspense fallback={<AppLoading />}>
      <AppRouter />
    </React.Suspense>
    </>
  )
}

export default App