import { createBrowserHistory } from 'history';
import agent from '../../agent';

const redirectMiddleware = ({ getState, dispatch }) => next => action => {
    if (action.type === '@@router/LOCATION_CHANGE') {
        // //alert(66)
        // const history = createBrowserHistory();
        // const isAdmin = agent.Auth.isAdmin();
        // const isAdminRoute = action?.payload?.location?.pathname?.includes('/admin');

        // if (isAdmin && !isAdminRoute) {
        //     history.location.pathname = '/admin';
        // }
        // return;
    }

    next(action);
};

export default redirectMiddleware;