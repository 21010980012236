import { push } from "connected-react-router";
import { showErrorMessage, showSuccessMessage } from './notification';
import agent from "../../agent";

// initial values
const defaultState = {
    events: {},
    event: {},
    registration: {},
    allEvents: [],
    allMyEvents: [],
    loading:false
};


// Action types
const CREATE_EVENT = 'KapsuulConnect/event/CREATE_EVENT';
const CREATE_EVENT_REGISTRATION = 'KapsuulConnect/event/CREATE_EVENT_REGISTRATION';
const LOAD_EVENTS = 'KapsuulConnect/event/LOAD_EVENTS';
const LOAD_ALL_EVENTS = 'KapsuulConnect/event/LOAD_ALL_EVENTS';
const LOAD_EVENT = 'KapsuulConnect/event/LOAD_EVENT';
const LOAD_MY_EVENTS = 'KapsuulConnect/event/LOAD_MY_EVENTS';
const LOADING = 'KapsuulConnect/event/LOADING';



// Reducer
export default function reducer(state = defaultState, action = {}) {
    switch (action.type) {
        case CREATE_EVENT:
            return {
                ...state,
                events: action.payload
            };
        case LOAD_EVENTS:
            return {
                ...state,
                events: action.payload
            };
        case LOAD_EVENT:
            return {
                ...state,
                event: action.payload
            };
        case CREATE_EVENT_REGISTRATION:
            return {
                ...state,
                registration: action.payload
            };
        case LOAD_ALL_EVENTS:
            return {
                ...state,
                allEvents: action.payload
            };
        case LOAD_MY_EVENTS:
            return {
                ...state,
                allMyEvents: action.payload
            };
            case LOADING:
                return {
                    ...state,
                    loading: action.payload
                };

        default: return state
    }
};

//Action Creators
export function CreateEvent(data) {
    return {
        type: CREATE_EVENT,
        payload: data
    };
}

export function CreateEventRegistration(data) {
    return {
        type: CREATE_EVENT_REGISTRATION,
        payload: data
    };
}

export function LoadEvent(data) {
    return {
        type: LOAD_EVENT,
        payload: data
    };
}

export function LoadEvents(data) {
    return {
        type: LOAD_EVENTS,
        payload: data
    };
}


export function LoadMyEvents(data) {
    return {
        type: LOAD_MY_EVENTS,
        payload: data
    };
}

export function LoadAllEvents(data) {
    return {
        type: LOAD_ALL_EVENTS,
        payload: data
    };
}

export function onLoading(data) {
    return {
        type: LOADING,
        payload: data
    };
}



//Actions
export function createEvent(event) {
    return dispatch => {
        dispatch(onLoading(true))
        return agent.Event.save(event).then(
            response => {
                dispatch(onLoading(false))
                dispatch(showSuccessMessage("Event successfully created"));
                dispatch(push("/events"));
            },
            error => {
                dispatch(onLoading(false))
                dispatch(showErrorMessage(error));
            }
        );
    }
}

export function loadEvents(search, pageNumber) {
    return dispatch => {
        return agent.Event.loadAll(search, pageNumber).then(
            response => {
                dispatch(LoadEvents(response));
            },
            error => {
                dispatch(showErrorMessage(error));
            }
        );
    }
}

export function loadMyEvents(search, pageNumber) {
    return dispatch => {
        return agent.Event.loadByUser(search, pageNumber).then(
            response => {
                dispatch(LoadEvents(response));
            },
            error => {
                dispatch(showErrorMessage(error));
            }
        );
    }
}

export function loadAllEvents(search, pageNumber) {
    return dispatch => {
        return agent.Event.loadAllEvents(search, pageNumber).then(
            response => {
                dispatch(LoadAllEvents(response));
            },
            error => {
                dispatch(showErrorMessage(error));
            }
        );
    }
}

export function editEvent(id, event) {
    return dispatch => {
        dispatch(onLoading(true))
        return agent.Event.edit(id, event).then(
            response => {
                //handle success
                dispatch(onLoading(false))
                dispatch(showSuccessMessage(("Event successfully updated")));
                dispatch(push("/events"));
            },
            error => {
                //handle error
                dispatch(onLoading(false))
                dispatch(showErrorMessage(error));
            }
        );
    }
}

export function loadEvent(id) {
    return dispatch => {
        return agent.Event.view(id).then(
            response => {
                dispatch(LoadEvent(response));

            },
            error => {
                //handle error
                // dispatch(showErrorMessage(error));
            }
        );
    }
}

export function deleteEvent(id) {
    return dispatch => {
        return agent.Event.delete(id).then(
            response => {
                //handle success
                dispatch(push('/events'));
                dispatch(LoadEvents());
                dispatch(showSuccessMessage(("Event successfully deleted")));
            },
            error => {
                //handle error
                dispatch(showErrorMessage(error));
            }
        );
    }
}
export function cancelEvent(id,reason) {
    return dispatch => {
        return agent.Event.cancel(id,reason).then(
            response => {
                //handle success
                // dispatch(push('/events'));
                dispatch(LoadEvents());
                dispatch(showSuccessMessage(("Event successfully cancelled")));
            },
            error => {
                //handle error
                dispatch(showErrorMessage(error));
            }
        );
    }
}

export function extendPublishedEvent(id,data) {
    return dispatch => {
        return agent.Event.extendPublishedEvent(id,data).then(
            response => {
                //handle success
                // dispatch(push('/events'));
                dispatch(LoadEvents());
                dispatch(showSuccessMessage(("Published event date successfully extended")));
            },
            error => {
                //handle error
                dispatch(showErrorMessage(error));
            }
        );
    }
}

// export function loadPublishedEvents(pageNumber) {
//     return dispatch => {
//         return agent.Event.publish(pageNumber).then(
//             response => {
//                 //handle success
//                 dispatch(LoadEvents(response));

//             },
//             error => {
//                 //handle error
//                 dispatch(showErrorMessage(error));
//             }
//         );
//     }
// }

export function publishEvents(id) {
    return dispatch => {
        return agent.Event.publish(id).then(
            response => {
                //handle success
                dispatch(showSuccessMessage(("Event has been published successfully")));
                dispatch(push('/admin/events'));
            },
            error => {
                //handle error
                dispatch(showErrorMessage(error));
            }
        );
    }
}
export function rejectEvents(id, reason) {
    return dispatch => {
        return agent.Event.reject(id, reason).then(
            response => {
                //handle success
                dispatch(showSuccessMessage(("Event has been rejected")));
                dispatch(push('/admin/events'));
            },
            error => {
                //handle error
                dispatch(showErrorMessage(error));
            }
        );
    }
}

export function loadUserEvents(search, pageNumber) {
    return dispatch => {
        return agent.Event.loadByUser(search, pageNumber).then(
            response => {
                //handle success
                dispatch(LoadEvents(response));
            },
            error => {
                //handle error
                dispatch(showErrorMessage(error));
            }
        );
    }
}

export function createRegistration(data) {
    return dispatch => {
        return agent.Event.registration(data).then(
            response => {
                //handle success
                dispatch(showSuccessMessage("You have successfully registered for this event, an email has been sent to your mail."));
                dispatch(push("/events"));
            },
            error => {
                //handle error
                dispatch(showErrorMessage(error));
            }
        );
    }
}

export function createRegistrationLanding(data) {
    return dispatch => {
        return agent.Event.registration(data).then(
            response => {
                //handle success
                dispatch(showSuccessMessage("You have successfully registered for this event"));
                dispatch(push("/events/active"));
            },
            error => {
                //handle error
                dispatch(showErrorMessage(error));
            }
        );
    }
}
