import { push } from "connected-react-router";
import { showErrorMessage, showSuccessMessage } from './notification';
import agent from "../../agent";

// initial values
const defaultState = {
    profession: []
};


// Action types
const LOAD_PROFESSION = 'KapsuulConnect/profession/LOAD_PROFESSION';



// Reducer
export default function reducer(state = defaultState, action) {
    switch (action.type) {

        case LOAD_PROFESSION:
            return {
                ...state,
                profession: action.payload
            };
        default: return state
    }
};

//Action Creators


export function LoadProfession(data) {
    return {
        type: LOAD_PROFESSION,
        payload: data
    };
}


//Actions


export function LoadAllProfession() {
    return dispatch => {
        return agent.Profession.loadAll().then(
            response => {
                dispatch(LoadProfession(response));
            },
            error => {
                dispatch(showErrorMessage(error));
            }
        );
    }
}

