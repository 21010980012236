import { push } from "connected-react-router";
import { showErrorMessage, showSuccessMessage } from './notification';
import agent from "../../agent";

// initial values
const defaultState = {
    typeOfPractice: []
};


// Action types
const CREATE_TYPE_OF_PRACTICE = 'KapsuulConnect/typeOfPractice/CREATE_TYPE_OF_PRACTICE';
const LOAD_TYPE_OF_PRACTICE = 'KapsuulConnect/typeOfPractice/LOAD_TYPE_OF_PRACTICE';



// Reducer
export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case CREATE_TYPE_OF_PRACTICE:
            return {
                ...state,
                typeOfPractice: action.payload
            };
        case LOAD_TYPE_OF_PRACTICE:
            return {
                ...state,
                typeOfPractice: action.payload
            };
        default: return state
    }
};

//Action Creators
export function CreateTypeOfPractice(data) {
    return {
        type: CREATE_TYPE_OF_PRACTICE,
        payload: data
    };
}

export function LoadTypeOfPractice(data) {
    return {
        type: LOAD_TYPE_OF_PRACTICE,
        payload: data
    };
}


//Actions
export function CreateTypeOfPractices(typeofpractice) {
    return dispatch => {
        return agent.TypeOfPractice.save(typeofpractice).then(
            response => {
                dispatch(showSuccessMessage("Type of practice successfully created"));
                dispatch(push("/admin/typeofpractice"));
            },
            error => {
                dispatch(showErrorMessage(error));
            }
        );
    }
}

export function LoadAllTypeOfPractice() {
    return dispatch => {
        return agent.TypeOfPractice.loadAll().then(
            response => {
                dispatch(LoadTypeOfPractice(response));
            },
            error => {
                dispatch(showErrorMessage(error));
            }
        );
    }
}

export function updateTypeOfPractice(id) {
    return dispatch => {
        return agent.TypeOfPractice.update(id).then(
            response => {
                //handle success
                dispatch(showSuccessMessage(("Type of practice successfully updated")));
                dispatch(push(`/admin/typeofpractice`));
            },
            error => {
                //handle error
                dispatch(showErrorMessage(error));
            }
        );
    }
}
export function loadTypeOfPractice(id) {
    return dispatch => {
        return agent.TypeOfPractice.load(id).then(
            response => {
                dispatch(LoadTypeOfPractice(response));

            },
            error => {
                //handle error
                // dispatch(showErrorMessage(error));
            }
        );
    }
}
export function deleteTypeOfPractice(id) {
    return dispatch => {
        return agent.TypeOfPractice.delete(id).then(
            response => {
                dispatch(push('/admin/typeofpractice'));
                dispatch(LoadTypeOfPractice());
                dispatch(showSuccessMessage(("Type of practice successfully deleted")));
            },
            error => {
                //handle error
                dispatch(showErrorMessage(error));
            }
        );
    }
}
